import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home"
import Footer from "./components/Footer";
import AdminLogin from "./AdminPanel/AdminLogin";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./AdminPanel/Main";
import Dashboard from "./AdminPanel/Dashboard";
import StudentList from "./AdminPanel/StudentList";
import FolderList from "./AdminPanel/FolderList";
import SubFolderList from "./AdminPanel/SubFolderList";
import Banner from "./AdminPanel/Banner";
import About from "./AdminPanel/About";
import BitsDoes from "./AdminPanel/BitsDoes";
import CourseList from "./AdminPanel/CourseList";
import StudentCourse from "./StudentPanel/StudentCourse";
import StudentFolder from "./StudentPanel/StudentFolder";
import StudentLogin from "./StudentPanel/StudentLogin";
import StudentDetails from "./StudentPanel/StudentDetails";

function App() {
  return <div className="App">
    <BrowserRouter>
    <Routes>
      <Route  path="/"
       element={<>
        <Navbar/>
        <Home/>
        <Footer/>
        </>} />

      {/* //-------------------------- Admin ----------------- */}

      <Route  path="/adminlogin"
      element={<AdminLogin/>}/>

      <Route path="/main"
      element={<Main/>}
      />

      <Route path="/dashboard"
      element={<Main  children={<Dashboard/>}/>}
      />
      <Route path="/studentlist"
      element={<Main  children={<StudentList/>}/>}
      />
      <Route path="/folderlist"
      element={<Main  children={<FolderList/>}/>}
      />
      <Route path="/subfolderlist"
      element={<Main  children={<SubFolderList/>}/>}
      />
      <Route path="/banner"
      element={<Main  children={<Banner/>}/>}
      />
      <Route path="/about"
      element={<Main  children={<About/>}/>}
      />
      <Route path="/bitsdoes"
      element={<Main  children={<BitsDoes/>}/>}
      />
      <Route path="/courselist"
      element={<Main  children={<CourseList/>}/>}
      />

    {/*------------ Student Panel ------------------------- */}
      <Route path="/studentlogin"
      element={<StudentLogin/>}/>

      <Route path="/studentdetails"
      element={<StudentLogin studentchild={<StudentDetails/>}/>}/>

      <Route path="/studentcourse"
      element={<StudentLogin studentchild={<StudentCourse/>}/>}/>
     
      <Route path="/studentfodler"
      element={<StudentLogin studentchild={<StudentFolder/>}/>}/>

    </Routes>
    </BrowserRouter>
  </div>;
}

export default App;
