import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const StudentCourse = () => {
  let navigate = useNavigate();

  let student=JSON.parse(sessionStorage.getItem("student"))

  const [StudentList, setStudentList] = useState([]);
  const getStudentlist = async () => {
    try {
      const getdata = await axios.get(
        "http://satechnology.org//api/admin/getstudentlist"
      );
      if (getdata) {
        setStudentList(getdata.data.studentlist?.filter((ele)=>ele._id === student?._id )?.[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    getStudentlist();
  }, []);

  const [Subfolder, setSubfolder] = useState([]);
  console.log("Subfolder",Subfolder);
  console.log("StudentList",StudentList );

  const getSubFolder = async () => {
    try {
      const getdata = await axios.get(
        "http://satechnology.org//api/admin/getsubfoldername"
      );
      if (getdata) {
        setSubfolder(getdata.data.subfolderlist);
        // ?.filter((ele)=>ele?.foldername === StudentList?.studentcourse)
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("StudentList?.studentcourse",StudentList?.studentcourse);
  
  useEffect(() => {
    getSubFolder();
  }, []);
  return (
    <div>
      <div className="row " style={{ borderBottom: "1px solid black" }}>
        <div className="input-group mt-2 mb-3" style={{ width: "550px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            aria-label="Search"
          />
          <span
            className="input-group-text"
            style={{ backgroundColor: "green" }}
          >
            <i className="fa fa-search"></i>
          </span>
        </div>
      </div>
      {/* ?.filter((ele)=>ele.assign===true) */}
      <div className="row mt-3">
        {[...new Set(Subfolder?.filter((ele)=>ele?.foldername === StudentList?.studentcourse)?.map((item) => item.foldername))]?.map(
          (foldername, index) => {
            const item = Subfolder.find((sub) => sub.foldername === foldername);
            return (
              <div className="col-md-2" key={index}>
                <i
                  className="fa fa-folder"
                  style={{
                    height: "50px",
                    fontSize: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate("/studentfodler", { state: { item: item } })
                  }
                />
                <p style={{ fontWeight: "bold" }}>{foldername}</p>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default StudentCourse;
