import React, { useEffect, useState } from "react";
import "../styles/studentdetails.css";
import axios from "axios";
import moment from "moment";

const StudentDetails = () => {
  let student = JSON.parse(sessionStorage.getItem("student"));
  let newDate = new Date();
  let tilldate = moment(newDate).format("YYYY-MM-DD");
  let expirydate = tilldate.replace(/-/g, "");
  console.log(expirydate, "sdivjzj");
  const [StudentList, setStudentList] = useState([]);
  console.log("studentlist", StudentList);

  const getStudentlist = async () => {
    try {
      const getdata = await axios.get(
        "http://satechnology.org//api/admin/getstudentlist"
      );
      if (getdata) {
        setStudentList(getdata.data.studentlist);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudentlist();
  }, []);

  return (
    <div className="row student-container">
      <div className="col-md-6 profile-section">
        <div className="profile-header">
          <h2 className="student-name">{student?.studentname}</h2>
          <p className="student-contact">Contact: {student?.studentmobile}</p>
          <p className="student-email">Email: {student?.studentemail}</p>
        </div>
      </div>

      <div className="col-md-6 courses-section">
        <h2>Courses</h2>
        <table className="courses-table">
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Start Date</th>
              <th>Expire Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {StudentList?.filter((ele) => ele?._id == student?._id).map(
              (item) => {
                return (
                  <tr>
                    <td>{item?.studentcourse}</td>
                    <td>
                      <strong>{item?.fromdate}</strong>
                    </td>
                    <td>
                      <strong>{item?.tilldate}</strong>
                    </td>

                    <td className="status-active">
                      {item?.tilldate ? (
      
                        console.log("Original Till Date:", item.tilldate) ||
            
                        console.log(
                          "Formatted Till Date:",
                          item.tilldate.replace(/-/g, "")
                        ) ||
                 
                        console.log("Expiry Date:", expirydate) ||
      
                        (item.tilldate.replace(/-/g, "") > expirydate ? (
                          <p>Active</p>
                        ) : (
                          <p style={{color:"red"}}>Expired</p>
                        ))
                      ) : (
                        <p>Till date not found</p>
                      )}
                    </td>
                  </tr>
                );
              }
            )}

            {/* <tr>
          <td>Course 2</td>
          <td>2023-05-01</td>
          <td>2024-04-30</td>
          <td className="status-expired">Expired</td>
        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentDetails;
