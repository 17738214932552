import React, { useEffect } from "react";
import  "../styles/main.css";
import { FaFolder, FaHome, FaInfo,FaUsers } from "react-icons/fa";
import { Sidebar, Menu, MenuItem, sidebarClasses, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import Dropdown from "react-bootstrap/Dropdown";
const Main = (props) => {

  let admin1=JSON.parse(sessionStorage.getItem("admin"))
  useEffect(() => {
  if(!admin1){
    window.location.assign("/adminlogin")
  }
  }, [admin1])
  
  const Logout = ()=>{
    sessionStorage.removeItem("admin")
    window.location.assign("/adminlogin")
  }
  return (
    <div>
      <div className="row me-0">
        <div className="col-md-2">
          <Sidebar
            rootStyles={{
              [`.${sidebarClasses.container}`]: {
                backgroundColor: "#19150C",
                color: "#b6c8d9",
                height: "559px",
              },
            }}
          >
            <Menu>
              {/* <img
                src="/Assets/logo.jpg"
                alt=""
                style={{ height: "100px", width: "100%" }}
              /> */}
           <h2  style={{ display:"flex",alignItems:"center",justifyContent:"center",
             height: "80px", width: "100%" }}>Bits Traffic</h2>
            </Menu>

            <Menu className="side-menu">
              <MenuItem
                component={<Link to="/dashboard" />}
                icon={<GridViewRoundedIcon />} >  Dashboard</MenuItem>
              
              <MenuItem
                component={<Link to="/studentlist" />}
                icon={<FaUsers />}  > Student List 
               </MenuItem>
             
        

              {/* <MenuItem
                suffix={
                  <span style={{ color: "white" }} className="count">
                    {Productlist?.length}{" "}
                  </span> }
                icon={<AiFillProduct />}
                component={<Link to="/product-list" />}>
                Folder List
              </MenuItem> */}
 
            
               <MenuItem
                component={<Link to="/folderlist" />}
               icon={<FaFolder />}> Course Name
               </MenuItem>

               <MenuItem
                component={<Link to="/subfolderlist" />}
               icon={<FaFolder />}> Course List 
               </MenuItem>

            <SubMenu label="Homepage" icon={<FaHome />}>
              <MenuItem 
               component={<Link to="/banner" />}
               icon={<AccountCircleRoundedIcon />}> Banner </MenuItem>
               <MenuItem 
                component={<Link to="/about" />}
                icon={<FaInfo />}> About Us </MenuItem>
               <MenuItem 
                component={<Link to="/bitsdoes" />}
                icon={<FaInfo />}> BittsDoes</MenuItem>
               </SubMenu>

               <MenuItem
                component={<Link to="/courselist" />}
                icon={<FaUsers />}  > Home Course
               </MenuItem>  
            </Menu>
          </Sidebar>
        </div>
        <div className="col-md-10"
         >
          <div className="dashboard">
            <div>
              <h2>Admin Dashboard</h2>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <AccountCircleRoundedIcon /> {admin1?.adminname}
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark">
                  <Dropdown.Item onClick={Logout}>
                    <LogoutRoundedIcon /> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div  style={{
            paddingLeft: "54px",
            overflowY: "scroll",
            height: "500px",
          }} > 
            
            {props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Main;
