import React, { useEffect } from "react";
import  "../styles/main.css";
import { FaAccessibleIcon, FaBook, FaFolder, FaHome, FaInfo,FaUsers } from "react-icons/fa";
import { Sidebar, Menu, MenuItem, sidebarClasses, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import Dropdown from "react-bootstrap/Dropdown";
const StudentLogin = (props) => {

  let student=JSON.parse(sessionStorage.getItem("student"))

  useEffect(() => {
  if(!student){
    window.location.assign("/")
  }
  }, [student])


  const Logout = ()=>{
    sessionStorage.removeItem("student")
    window.location.assign("/")
  }
  
  return (
    <div>
      <div className="row me-0">
        <div className="col-md-2">
          <Sidebar
            rootStyles={{
              [`.${sidebarClasses.container}`]: {
                backgroundColor: "#2c3e50",
                color: "#b6c8d9",
                height: "559px",
              },
            }}
          >
            <Menu>
              {/* <img
                src="/Assets/logo.jpg"
                alt=""
                style={{ height: "100px", width: "100%" }}
              /> */}
           <h2  style={{ display:"flex",alignItems:"center",justifyContent:"center",
             height: "80px", width: "100%" }}>Bits Traffic</h2>
            </Menu>

            <Menu className="side-menu">
              <MenuItem
                component={<Link to="/studentdetails" />}
                icon={<GridViewRoundedIcon />} >Student  Details</MenuItem>

               {/* <MenuItem
                component={<Link to="/studentfodler" />}
               icon={<FaFolder />}>All courses
               </MenuItem> */}

               <MenuItem
                component={<Link to="/studentcourse" />}
                icon={<FaBook />}  > Course List 
               </MenuItem>  
            </Menu>
          </Sidebar>
        </div>
        <div className="col-md-10"
          style={{
            paddingLeft: "54px",
            overflow: "hidden",
            overflowY: "scroll",
            height: "551px",
          }} >
          <div className="dashboard">
            <div>
              <h2>Welcome Student</h2>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                Hi , {student?.studentname}  <AccountCircleRoundedIcon /> 
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark">
                  {/* <Dropdown.Item>
                    <AccountCircle /> My Profile
                  </Dropdown.Item> */}
                  <Dropdown.Item onClick={Logout}>
                    <LogoutRoundedIcon /> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div > {props.studentchild}</div>
        </div>
      </div>
    </div>
  );
};

export default StudentLogin;
