import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FilterList } from "@mui/icons-material";
import ReactPaginate from 'react-paginate';

const StudentList = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // Open/Close Add Modal
  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  // Open/Close Edit Modal
  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);

  // assign modl
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [StudentId, setStduentId] = useState("");
  const [StudentName, setStudentName] = useState("");
  const [StudentMobile, setStudentMobile] = useState("");
  const [StudentEmail, setStudentEmail] = useState("");
  const [StudentCourse, setStudentCourse] = useState("");
  const [StudentPassword, setStudentPassword] = useState("");
  const [Fromdate, setFromdate] = useState("");
  const [Tilldate, setTilldate] = useState("");

  const addStudentlist = async () => {
    try {
      const formData = new FormData();
      formData.append("studentid", StudentId);
      formData.append("studentname", StudentName);
      formData.append("studentmobile", StudentMobile);
      formData.append("studentemail", StudentEmail);
      formData.append("studentcourse", StudentCourse);
      formData.append("studentpassword", StudentPassword);
      formData.append("fromdate", Fromdate);
      formData.append("tilldate", Tilldate);

      const config = {
        url: "/addstudentlist",
        method: "POST",
        baseURL: "http://satechnology.org//api/admin",
        headers: { "Content-Type": "application/json" },
        data: formData,
      };

      let res = await axios(config); // Make request with axios
      if (res.status === 200) {
        alert(res.data.success);
        closeAddModal();
        getStudentlist();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  const [StudentList, setStudentList] = useState([]);
  const getStudentlist = async () => {
    try {
      const getdata = await axios.get(
        "http://satechnology.org//api/admin/getstudentlist"
      );
      if (getdata) {
        setStudentList(getdata.data.studentlist);
        setFilteredStudentList(getdata.data.studentlist);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudentlist();
  }, []);

  const deleteStudentList = async (sid) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Package Title?"
    );
    if (confirmed) {
      try {
        const deletedata = await axios.delete(
          `http://satechnology.org//api/admin/deletestudentlist/${sid}`
        );
        if (deletedata.status === 200) {
          alert(deletedata.data.success);
          getStudentlist();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  const [EditStudentId, setEditStduentId] = useState("");
  const [EditStudentName, setEditStudentName] = useState("");
  const [EditStudentMobile, setEditStudentMobile] = useState("");
  const [EditStudentEmail, setEditStudentEmail] = useState("");
  const [EditStudentCourse, setEditStudentCourse] = useState("");
  const [EditStudentPassword, setEditStudentPassword] = useState("");
  const [EditFromdate, setEditFromdate] = useState("");
  const [EditTilldate, setEditTilldate] = useState("");
  const [Sid, setSid] = useState("");

  const editStudentlist = async () => {
    try {
      const formData = new FormData();
      formData.append("studentid", EditStudentId);
      formData.append("studentname", EditStudentName);
      formData.append("studentmobile", EditStudentMobile);
      formData.append("studentemail", EditStudentEmail);
      formData.append("studentcourse", EditStudentCourse);
      formData.append("studentpassword", EditStudentPassword);
      formData.append("fromdate", EditFromdate);
      formData.append("tilldate", EditTilldate);

      formData.append("id", Sid);

      const config = {
        url: "/editstudentlsit",
        method: "put",
        baseURL: "http://satechnology.org//api/admin",
        headers: { "Content-Type": "application/json" },
        data: formData,
      };

      let res = await axios(config); // Make request with axios

      if (res.status === 200) {
        alert(res.data.success);
        closeEditModal();
        getStudentlist();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  let[Folder,setFolder]=useState([])

  const getFoldername=async ()=>{
    try {
      const getdata=await axios.get("http://satechnology.org//api/admin/getfoldername")
      if(getdata){
        setFolder(getdata.data.folderlist)
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    getFoldername()
  }, [])

  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  console.log(startDate, endDate, "startDate");
  const filterStudentsByDate = () => {
    if (startDate && endDate) {
      const filtered = StudentList.filter((student) => {
        const enrolledDate = moment(student.createdAt);
        return enrolledDate.isBetween(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          "days",
          "[]"
        );
      });
      setFilteredStudentList(filtered);
    } else {
      setFilteredStudentList(StudentList);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      StudentList.map((student, index) => ({
        "S.No": index + 1,
        "Student Id": student.studentid,
        Name: student.studentname,
        Mobile: student.studentmobile,
        Email: student.studentemail,
        "Selected Course": student.studentcourse,
        Password: student.studentpassword,
        "Timeframe / Period": "6 months", // You can replace it dynamically based on your data
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "StudentList");

    // Trigger file download
    XLSX.writeFile(workbook, "StudentList.xlsx");
  };

  const [SelectedStudentdetails, setSelectedStudentdetails] = useState({});
  const assignCourse = async () => {
    try {
      const config ={
        url:"/assigncourse",
        method:"put",
        baseURL:"http://satechnology.org//api/admin",
        headers:{"content-type":"application/json"},
        data:{
          userId:SelectedStudentdetails?._id
        }
      }
      const res = await axios(config);        
      if(res.status === 200){
        alert(res.data.success);
        handleClose()
        getStudentlist()
      }
    } catch (error) {
      alert(error.response?.data?.error || 'Something went wrong');
    }
  };


  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5); // Change this to whatever number of items you want per page
  const itemsPerPage = 5; 
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const pageCount = Math.ceil(filteredStudentList.length / itemsPerPage);

  // Calculate the students to display based on current page
  const displayedStudents = filteredStudentList.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="container mt-2">
      <div className="input-group mb-3" style={{ width: "350px" }}>
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          aria-label="Search"
        />
        <span className="input-group-text" style={{ backgroundColor: "green" }}>
          <i className="fa fa-search"></i>
        </span>
      </div>

      <div
        className="display"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Student Details</h2>
        <div style={{ display: "flex", gap: "11px" }}>
          <input
            type="date"
            className="form-control"
            selected={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <h3>To</h3>
          <input
            type="date"
            className="form-control"
            selected={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-success"
            onClick={filterStudentsByDate}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              getStudentlist();
            }}
          >
            Refresh
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={exportToExcel}
          >
            Export
          </button>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={openAddModal}
        >
          <i className="fas fa-plus"></i> Add
        </button>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeAddModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Student_Id</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setStduentId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter name"
                      onChange={(e) => {
                        setStudentName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      onChange={(e) => {
                        setStudentEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        setStudentMobile(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Create Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      onChange={(e) => {
                        setStudentPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Select Course
                    </label>
                    <select
                      className="form-select"
                      id="course"
                      onChange={(e) => {
                        setStudentCourse(e.target.value);
                      }}
                    >
                      <option value="">Choose a course</option>
                      {Folder?.map((item, i) => {
                        return (
                          <option value={item?.foldername}>
                            {item?.foldername}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Slect Time-Period</label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "50px",
                      }}
                    >
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setFromdate(e.target.value)}
                      />
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setTilldate(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addStudentlist}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table
        className="table table-bordered mt-2 "
        style={{ overflowX: "scroll" }}
      >
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            <th>Enrolled Date</th>
            <th> Student Id</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Password</th>
            <th>Selected Course</th>
            <th>Durration Of Course</th>
            <th>Assign</th>
            <div style={{ width: "107px", height: "87px" }}>
              <th>Action</th>
            </div>
          </tr>
        </thead>
        <tbody>
          {displayedStudents?.map((item, i) => {
            return (
              <tr>
                <td>{++i}</td>
                <td>{moment(item?.createdAt).format("YYYY-MM-DD")}</td>
                <td>{item?.studentid}</td>
                <td>{item?.studentname}</td>
                <td>{item?.studentmobile}</td>
                <td>{item?.studentemail}</td>
                <td>{item?.studentpassword}</td>
                <td>{item?.studentcourse}</td>
                <td>
                  {(() => {
                    const fromDate = new Date(item?.fromdate);
                    const tillDate = new Date(item?.tilldate);

                    let years = tillDate.getFullYear() - fromDate.getFullYear();
                    let months = tillDate.getMonth() - fromDate.getMonth();
                    let days = tillDate.getDate() - fromDate.getDate();

                    if (days < 0) {
                      months--;
                      days += new Date(
                        fromDate.getFullYear(),
                        fromDate.getMonth() + 1,
                        0
                      ).getDate();
                    }

                    if (months < 0) {
                      years--;
                      months += 12;
                    }
                    const durationParts = [];

                    if (years > 0) {
                      durationParts.push(
                        `${years} year${years > 1 ? "s" : ""}`
                      );
                    }
                    if (months > 0) {
                      durationParts.push(
                        `${months} month${months > 1 ? "s" : ""}`
                      );
                    }
                    if (days > 0) {
                      durationParts.push(`${days} day${days > 1 ? "s" : ""}`);
                    }
                    return durationParts.length > 0
                      ? durationParts.join(", ")
                      : "0 days";
                  })()}
                </td>
                <td>
                  {item?.assign === true ? (<>
                  <b style={{color:"green"}}>Assign Succesfully</b>
                  </>):(<>
                    <Button
                    variant="primary"
                    onClick={() => {
                      handleShow();
                      setSelectedStudentdetails(item);
                    }}
                  >
                    Assign
                  </Button></>)}
                  
                </td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      openEditModal();
                      setSid(item?._id);
                      setEditStduentId(item?.studentid);
                      setEditStudentName(item?.studentname);
                      setEditStudentMobile(item?.studentmobile);
                      setEditStudentEmail(item?.studentemail);
                      setEditStudentPassword(item?.studentpassword);
                      setEditStudentCourse(item?.studentcourse)
                      setEditFromdate(item?.fromdate);
                      setEditTilldate(item?.tilldate);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      deleteStudentList(item?._id);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
     <ReactPaginate
        previousLabel={"< Previous"}
        nextLabel={"Next >"}
        // breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
      {/* Edit Modal */}
      {showEditModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeEditModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Student Id</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={EditStudentId}
                      onChange={(e) => {
                        setEditStduentId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter name"
                      value={EditStudentName}
                      onChange={(e) => {
                        setEditStudentName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      value={EditStudentEmail}
                      onChange={(e) => {
                        setEditStudentEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      placeholder="Enter phone number"
                      value={EditStudentMobile}
                      onChange={(e) => {
                        setEditStudentMobile(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Update Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={EditStudentPassword}
                      onChange={(e) => {
                        setEditStudentPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Update Course
                    </label>
                    <select
                      className="form-select"
                      id="course"
                      value={EditStudentCourse}
                      onChange={(e) => {
                        setEditStudentCourse(e.target.value);
                      }}
                    >
                      <option value="">Choose a course</option>
                      {Folder?.map((item, i) => {
                        return (
                          <option value={item?.foldername}>
                            {item?.foldername}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Upadte Time-Period</label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "50px",
                      }}
                    >
                      <input
                        type="date"
                        className="form-control"
                        value={EditFromdate}
                        onChange={(e) => setEditFromdate(e.target.value)}
                      />
                      <input
                        type="date"
                        className="form-control"
                        value={EditTilldate}
                        onChange={(e) => setEditTilldate(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={editStudentlist}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <>
      <Modal show={show} onHide={handleClose} animation={false} className="assignment-modal">
      <Modal.Header >
        <Modal.Title>Confirm Assigning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content">
          <p className="modal-message">
            Are you sure you want to assign this course to <strong>{SelectedStudentdetails?.studentname}</strong>?
          </p>
          <p className="modal-date">
            From: <strong>{SelectedStudentdetails?.fromdate}</strong> to <strong>{SelectedStudentdetails?.tilldate}</strong>
          </p>
          <p className="modal-course">
            Course Name: <strong>{SelectedStudentdetails?.studentcourse}</strong>
          </p>
          <p className="modal-course">
            Course Durration: <strong>  {(() => {
                    const fromDate = new Date(SelectedStudentdetails?.fromdate);
                    const tillDate = new Date(SelectedStudentdetails?.tilldate);

                    let years = tillDate.getFullYear() - fromDate.getFullYear();
                    let months = tillDate.getMonth() - fromDate.getMonth();
                    let days = tillDate.getDate() - fromDate.getDate();

                    if (days < 0) {
                      months--;
                      days += new Date(
                        fromDate.getFullYear(),
                        fromDate.getMonth() + 1,
                        0
                      ).getDate();
                    }

                    if (months < 0) {
                      years--;
                      months += 12;
                    }
                    const durationParts = [];

                    if (years > 0) {
                      durationParts.push(
                        `${years} year${years > 1 ? "s" : ""}`
                      );
                    }
                    if (months > 0) {
                      durationParts.push(
                        `${months} month${months > 1 ? "s" : ""}`
                      );
                    }
                    if (days > 0) {
                      durationParts.push(`${days} day${days > 1 ? "s" : ""}`);
                    }
                    return durationParts.length > 0
                      ? durationParts.join(", ")
                      : "0 days";
                  })()}</strong>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={assignCourse}>
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
      </>
    </div>
  );
};

export default StudentList;
