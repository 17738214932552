import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "@fortawesome/fontawesome-free/css/all.min.css"; // Font Awesome
import axios from "axios";
import { useLocation } from "react-router-dom";

const SubFolderList = () => {
 
  const location = useLocation();
  // The state passed from Page1
  const receivedData = location.state;
 

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleAddModal = () => setShowAddModal(!showAddModal);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const [Foldername, setFoldername] = useState("");
  const [Subfoldername, setSubfoldername] = useState("");
  const [Pdf, setPdf] = useState("");
  const [Lecture, setLecture] = useState("");

  const addSubfolder = async () => {
    try {
      const formData = new FormData();
      formData.append("foldername", Foldername);
      formData.append("subfoldername", Subfoldername);
      formData.append("pdf", Pdf);
      formData.append("lecture", Lecture);
      const config = {
        url: "/addsubfoldername",
        method: "post",
        baseURL: "http://satechnology.org//api/admin",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      };
      let res = await axios(config); // Make request with axios

      if (res.status === 200) {
        alert(res.data.success);
        toggleAddModal();
        getSubFolder();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  const [Subfolder, setSubfolder] = useState([]);
  const getSubFolder = async () => {
    try {
      const getdata = await axios.get(
        "http://satechnology.org//api/admin/getsubfoldername"
      );
      if (getdata) {
        setSubfolder(getdata.data.subfolderlist);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubFolder();
  }, []);

  const deleteSubfolder = async (textid) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Package Title?"
    );
    if (confirmed) {
      try {
        const deletedata = await axios.delete(
          `http://satechnology.org//api/admin/deletesubfoldername/${textid}`
        );
        if (deletedata.status === 200) {
          alert(deletedata.data.success);
          getSubFolder();
        }
      } catch (error) {
        alert(error.response.data.error);
      }
    }
  };

  const [EditFoldername, setEditFoldername] = useState("");
  const [EditSubfoldername, setEditSubfoldername] = useState("");
  const [subfolderid, setSubfolderid] = useState("");
  const editSubfolder = async () => {
    try {
      const config = {
        url: "/editsubfoldername",
        method: "put",
        baseURL: "http://satechnology.org//api/admin",
        headers: { "Content-Type": "application/json" },
        data: {
          id: subfolderid,
          foldername: EditFoldername,
          subfoldername: EditSubfoldername,
        },
      };
      let res = await axios(config); // Make request with axios

      if (res.status === 200) {
        alert(res.data.success);
        toggleEditModal();
        getSubFolder();
      }
    } catch (error) {
      alert(error.response?.data?.error || "Something went wrong");
    }
  };

  let [Folder, setFolder] = useState([]);
  const getFoldername = async () => {
    try {
      const getdata = await axios.get(
        "http://satechnology.org//api/admin/getfoldername"
      );
      if (getdata) {
        setFolder(getdata.data.folderlist);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFoldername();
  }, []);
  return (
    <div className="container mt-2">
      <div
        className="display"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Sub-Folder List </h2>
        <button
          type="button"
          className="btn btn-primary"
          onClick={toggleAddModal}
        >
          <i className="fas fa-plus"></i> Add
        </button>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Folder</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleAddModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Select Course
                    </label>
                    <select
                      className="form-select"
                      id="course"
                      onChange={(e) => setFoldername(e.target.value)}
                    >
                      <option>Choose a Course</option>
                      {Folder?.map((item, i) => {
                        return (
                          <option value={item?.foldername}>
                            {item?.foldername}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      {" "}
                     Subject Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter title"
                      onChange={(e) => {
                        setSubfoldername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pdf" className="form-label">
                      Add Pdf
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setPdf(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="video" className="form-label">
                      Add Video
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setLecture(e.target.files[0]);
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addSubfolder}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Student Table */}
      <table className="table table-bordered mt-2">
        <thead className="table-light">
          <tr>
            <th>S.No</th>
            <th>Course Name</th>
            <th>Subject Name</th>
            <th>Lecture No</th>
            <th>Pdf</th>
            <th>Video Lecture</th>
            <th style={{ width: 155 }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {Subfolder?.map((item, i) => {
            return (
              <tr>
                <td>{++i}</td>
                <td>{item?.foldername}</td>
                <td>

                  {/* <i className="fa fa-folder-open" /> */}
                  {item?.subfoldername}
                </td>
                <td>{i+1}</td>
                <td> {item?.pdf?(<a href={`http://satechnology.org//SubFolder/${item?.pdf}`} target="_blank" rel="noopener noreferrer">
                Download/View PDF</a>):(<p>You didn't upload any pdf and file</p>)}
                  
                </td>
                <td> {item?.lecture?(<video width="250" controls>
            <source src={`http://satechnology.org//SubFolder/${item?.lecture}`} type="video/mp4"  />
             </video>):(<><p>You didn't upload video</p></>)}</td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      toggleEditModal();
                      setSubfolderid(item?._id);
                      setEditFoldername(item?.foldername);
                      setEditSubfoldername(item?.subfoldername);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{ marginLeft: "11px" }}
                    onClick={() => {
                      deleteSubfolder(item?._id);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Sub-Folder</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleEditModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Select Folder
                    </label>
                    <select
                      className="form-select"
                      id="course"
                      onChange={(e) => setEditFoldername(e.target.value)}
                    >
                      <option>Choose a Folder</option>
                      {Folder?.map((item, i) => {
                        return (
                          <option value={item?.foldername}>
                            {item?.foldername}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Sub Folder Name
                    </label>
                    <input
                      type="text"
                      className="form-control" value={EditSubfoldername}
                      onChange={(e) => {
                        setEditSubfoldername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pdf" className="form-label">
                      Add Pdf
                    </label>
                    <input type="file" className="form-control" id="name" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="video" className="form-label">
                      Add Video
                    </label>
                    <input type="file" className="form-control" id="name" />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={editSubfolder}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubFolderList;
